import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div>
	Hello World - Just Policy Info in construction.
    </div>
  );
}

export default App;
